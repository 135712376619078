<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <svg
          @click="goToPreviousPage"
          class="back-arrow"
          width="32"
          height="25"
          viewBox="0 0 32 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.570872 11.1186L11.0923 0.571695L13.8454 0.571695L13.8454 3.33452L6.65089 10.5469L30.0508 10.5469L32 12.4999L30.0508 14.4531L6.65089 14.4531L13.8454 21.6654L13.8454 24.4284L12.47 25L11.0923 24.4284L0.570872 13.8816L0.570872 11.1186Z"
            fill="#FFCD02"
          />
        </svg>
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Bent u eigenaar van de woning?</p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
                class="align-items-center"
              >
                <div class="check-item" @click="handleClick('4091')">
                  <b-form-radio value="4091"
                    >Ja, ik ben eigenaar van de woning.
                  </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('4094')">
                  <b-form-radio value="4094"
                    >Nee, ik ben (nog) geen eigenaar</b-form-radio
                  >
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection :details="details" />
        <!-- <div
          class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
        >
          <span>Walter gropiuserf 6, Amersfoort</span>
          <span>{{ address?.results[0]?.formatted_address }}</span>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "SecondQuestion",
  data() {
    return {
      selected: "",
    };
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  components: {
    GoogleMapSection,
  },
  methods: {
    handleClick(val) {
      if (val == "4091") {
        this.$emit("nextQuestion", "3", val);
      } else {
        this.$emit("nextQuestion", "2B", val);
      }
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "1");
    },
  },
  watch: {
    selected() {
      if (this.selected == "4091") {
        this.$emit("nextQuestion", "3", this.selected);
      } else {
        this.$emit("nextQuestion", "2B", this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    // background-image: url(../../assets/imgs/secondQuestion.png);
    // background-size: 100% 100% !important;
  }
}
</style>
