<template>
  <div class="work-card d-flex flex-column">
    <div class="">
      <img :src="item.icon" class="work-card__icon w-100" />
    </div>
    <div class="work-card__main">
      <p class="work-card__title mb-0 mt-3">{{ item?.step }}</p>
      <ul>
        <li class="work-card__description mt-2">{{ item?.description1 }}</li>
        <li class="work-card__description mt-2">{{ item?.description2 }}</li>
        <li
          class="work-card__description mt-2"
          :style="item.step === 'Dakrenovatie' ? { marginBottom: '60px' } : {}"
        >
          {{ item?.description3 }}
        </li>
        <li v-if="item?.description4" class="work-card__description mt-2">
          {{ item?.description4 }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkCard",
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700;800;900&family=DM+Sans&display=swap");
.work-card {
  padding: 30px 20px;
  // background: #ffffff;
  margin-top: 80px;
  min-height: 199px;

  &__icon {
    // width: 90px;
    // height: 90px;
    // border-radius: 50%;
    // padding: 10px;
    // background: #ffcc01;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    // img {
    //   width: 100% !important;
    // }
  }
  &__main {
    background-color: #ffe785;
    padding: 21px;
  }
  &__title {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #1c1829;
    font-weight: 800;

    text-align: start;
  }
  &__description {
    font-family: "Catamaran", sans-serif;
    font-size: 20px;
    // line-height: 24px;
    line-height: 20px;
    color: #1c1829;
    font-weight: 400;

    text-align: start;
  }

  @media (min-width: 768px) and (max-width: 1199.99px) {
    min-height: 219px !important;
    &__icon {
      margin-top: -95px;
    }
  }
}
</style>
