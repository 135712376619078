<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <p class="question-card__question">
          Je bent geen eigenaar van de woning, helaas kunnen wij je dan niet
          verder helpen.
        </p>

        <div
          class="d-flex align-items-center cursor--pointer"
          @click="handleNextQuestion"
        >
          <img src="@/assets/imgs/arrowleft.png" />
          <span class="back-text ml-2">Ga terug</span>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col"> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SecondCQuestion",
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleNextQuestion() {
      this.$emit("nextQuestion", "2B");
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/thirdQuestion.png);
    background-size: 100% 100% !important;
  }

  .back-text {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 33px;
    color: #1d1729;
    font-weight: 600;
  }
}
</style>
