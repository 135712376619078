<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />

        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Uit welk materiaal bestaat je gevel?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <!-- <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
                >
                  <div class="check-item" @click="handleClick('3884')">
                    <b-form-radio value="3884">Schuin dak </b-form-radio>
                  </div>
  
                  <div class="check-item mt-3" @click="handleClick('3881')">
                    <b-form-radio value="3881">Plat dak</b-form-radio>
                  </div>
                </b-form-radio-group>
              </b-form-group> -->
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div
                  class="check-item mt-3"
                  @click="handleClick(['4371', '4330'])"
                >
                  <b-form-radio value="4371"
                    >Bakstenen (onbehandeld)
                  </b-form-radio>
                </div>
                <div
                  class="check-item mt-3"
                  @click="handleClick(['4374', '4383'])"
                >
                  <b-form-radio value="4374">Geschilderde gevel</b-form-radio>
                </div>
                <div
                  class="check-item mt-3"
                  @click="handleClick(['4377', '4386'])"
                >
                  <b-form-radio value="4377">Bepleisterde gevel</b-form-radio>
                </div>
                <div class="check-item mt-3" @click="handleClick('4380')">
                  <b-form-radio value="4380">Anders</b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection :details="details" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import { BModal, BButton } from "bootstrap-vue";

export default {
  name: "FourthA1_Question",

  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", "4A2", val);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },

  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
//.question-card {
// &__right-col {
//   background-image: url(../../../assets/imgs/secondQuestion.png);
//   background-size: 100% 100% !important;
// }
//}
</style>
