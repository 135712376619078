import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      myrules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    ...mapGetters([
      "leadLoading",
      "leadResponse",
      "address",
      "location",
      "addressDetails",
      "allStreets",
    ]),
  },
  mounted() { },
  methods: {
    ...mapActions([
      "setAnswers",
      "createLead",
      "getAddress",
      "getStreets",
      "getStreetsByZip",
      "airconditioningCreateLead",
      "createIsolateLead",
      "createWaterontharderLead",
      "dakwerkenCreateLead"

    ]),
  },
};
