import axios from "axios";
import authHeader from "./AuthHeader";
import qs from "qs";
const API_URL = "https://leadgen.republish.nl/api";
class LeadService {
  createLead(payload) {
    return axios.post(API_URL + "/sponsors/2207/leads", payload, {
      headers: authHeader(),
    });
  }
  createLeadAirconditioning(payload) {
    return axios.post(API_URL + "/sponsors/2282/leads", payload, {
      headers: authHeader(),
    });
  }
  createIsolateLead(payload) {
    return axios.post(API_URL + "/sponsors/2285/leads", qs.stringify(payload), {
      headers: authHeader(),
    });
  }
  createWaterontharderLead(payload) {
    return axios.post(API_URL + "/sponsors/2344/leads", qs.stringify(payload), {
      headers: authHeader(),
    });


  }
  createLeadDakwerkan(payload) {
    return axios.post(API_URL + "/sponsors/2361/leads", qs.stringify(payload), {
      headers: authHeader(),
    });
  }
}

export default new LeadService();
