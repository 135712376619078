<template>
  <div class="hero-section">
    <!-- :style="{
      'background-image': 'url(' + require('@/assets/imgs/21341234.png') + ')',
    }" -->
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-column position-relative hero-section__main-col"
        >
          <p class="hero-section__title pl-md-1">
            BESPAREN OP <br />

            <span class="hero-section__white"> WATERONTHARDER </span>
          </p>

          <p class="my-md-3 my-1 hero-section__text">
            Met onze unieke scan checken wij:
          </p>

          <div
            class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
          >
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >De best beschikbare offertes voor jouw woning;</span
            >
          </div>
          <!-- <div class="d-flex align-items-center mt-1 mt-md-2"> -->
          <!-- <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            /> -->

          <!-- <span class="hero-section__text ml-1 ml-md-3"
              >Of je recht hebt op premie;</span
            > -->
          <!-- </div> -->

          <div
            class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
          >
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >De juiste vakmannen voor gratis vrijblijvend, persoonlijk
              advies;</span
            >
          </div>
          <div
            class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
          >
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Ontvang 4 gratis offertes van lokale en gecertificeerde
              partners</span
            >
          </div>
          <span class="mt-md-2 mt-1 hero-section__text"
            >Vergelijk en bespaar tot 40% op de aanschafprijs!</span
          >

          <div class="d-flex align-items-center">
            <div>
              <b-button
                class="hero-section__btn position-relative"
                @click="$emit('nextPage')"
              >
                START DE GRATIS SCAN!

                <p class="hero-section__sub-text position-absolute">
                  Klaar binnen 1 minuut
                </p>
              </b-button>
            </div>
            <div
              class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
            >
              <span>
                Bespaar tot <br />
                wel 40%</span
              >
            </div>
          </div>

          <img
            src="@/assets/imgs/arrow.png"
            class="position-absolute arrow"
            alt="My Image"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";

export default {
  name: "HeroSection",
};
</script>

<style lang="scss" scoped>
.hero-section {
  background-image: url(../../assets/imgs/headerwateron.png);
  min-height: 100px !important;
  background-size: 100% 100% !important;

  padding-top: 70px;
  padding-bottom: 70px;

  overflow: hidden;
  @media (max-width: 575.98px) {
    background-image: url("../../assets/imgs/headermobilewateron.png");
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }

  &__title {
    // font-size: 64px;
    font-size: 38px;
    // line-height: 58px;
    line-height: 38px;
    text-transform: uppercase;
    color: white !important;
    font-weight: 800;
  }
  &__white {
    color: #ffcc01 !important;
  }

  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 15px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 20px;
    top: 60px;
  }
  &__div {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #1d1729;
    font-weight: 800;
    font-size: 15px;
    text-align: center;
    color: #ffcc01;
  }
  &__btn {
    font-size: 17px;
    // font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    background: #ffcc01;
    margin-top: 20px;
    border-radius: 30px;
    padding: 15px 30px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  .arrow {
    left: -80px !important;
    bottom: 15%;
  }

  &__main-col {
    margin-left: 50% !important;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 30px;
    }
    &__div span {
      font-size: 10px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
    .arrow {
      bottom: 10%;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: 8% !important;
    }
    &__title {
      font-size: 35px;
    }
    &__div span {
      font-size: 10px;
    }
    &__text {
      font-size: 14px;
    }
    &__sub-text {
      font-size: 12px;
      top: 50px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -40px !important;
      bottom: 9%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__main-col {
      margin-left: 5% !important;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }
    &__div span {
      font-size: 10px;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
    }
    &__btn {
      font-size: 10px;
      padding: 8px 16px;
      letter-spacing: 0.2px;
    }
    .arrow {
      display: none;
    }
    .check-img {
      width: 15px !important;
    }
  }

  @media (max-width: 625.99px) {
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 5% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }

    &__text {
      font-size: 10px;
    }
    &__sub-text {
      font-size: 8px;
      top: 30px;
    }
    &__btn {
      font-size: 8px;
      padding: 7px 14px;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 70px;
    padding-bottom: 20px;
    .check-img {
      width: 10px !important;
    }
    &__main-col {
      margin-left: 11% !important;
    }
    &__title {
      font-size: 25px;
      line-height: 20px !important;
      margin-bottom: 5px !important;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 37px;
    }
    &__btn {
      font-size: 15px;
      padding: 7px 14px;
      margin-top: 10px;
    }
    &__div span {
      font-size: 10px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 374.99px) {
    &__main-col {
      margin-left: 11% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 20px !important;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 31px;
    }
    &__btn {
      font-size: 13px;
      padding: 6px 12px;
    }
  }
  @media (max-width: 319.99px) {
    &__btn {
      font-size: 10px;
      padding: 6px 12px;
    }
  }
}
</style>
