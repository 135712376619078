<template>
  <div class="questions-section">
    <b-container>
      <b-row
        class="justify-content-center"
        v-if="
          !(question === '2C' || question === 'form' || question === 'analyze')
        "
      >
        <b-col cols="12" xl="9">
          <b-progress :max="max">
            <b-progress-bar
              :value="value"
              :label="`${Math.ceil((value / max) * 100)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4">
        <b-col xl="9" cols="12" v-if="question == '1'">
          <FirstQuestion
            :question="question"
            :max="max"
            :details="details"
            @nextQuestion="nextQuestion"
            @selectedStreet="setSelectedStreet"
            @previousPage="goToPrevious"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '2'">
          <SecondQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2B'">
          <SecondBQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2C'">
          <SecondCQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <!-- <b-col xl="9" cols="12" v-else-if="question == ''">
          <ThirdQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col> -->
        <b-col xl="9" cols="12" v-else-if="question == '4'">
          <FourthQuestion
            :question="question"
            :max="max"
            :details="details"
            :text_answers="text_answers"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '3'">
          <FifthQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4" v-if="question == 'analyze'">
        <b-col xl="9" cols="12">
          <AnalyzingSection :details="details" @nextQuestion="nextQuestion" />
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4" v-if="question == 'form'">
        <b-col xl="9" cols="12">
          <FormSection
            :details="details"
            :text_answers="text_answers"
            :answers="answers"
            @nextQuestion="nextQuestion"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FirstQuestion from "../../components/waterontharder/Questions/FirstQuestion.vue";
import SecondQuestion from "../../components/waterontharder/Questions/SecondQuestion.vue";
import SecondBQuestion from "../../components/waterontharder/Questions/SecondBQuestion.vue";
import SecondCQuestion from "../../components/waterontharder/Questions/SecondCQuestion.vue";
import ThirdQuestion from "../../components/waterontharder/Questions/ThirdQuestion.vue";
import FourthQuestion from "../../components/waterontharder/Questions/FourthQuestion.vue";
import FifthQuestion from "../../components/waterontharder/Questions/FifthQuestion.vue";
import AnalyzingSection from "../../components/waterontharder/AnalyzingSection.vue";
import FormSection from "../../components/waterontharder/Form.vue";
export default {
  name: "QuestionsSection",
  components: {
    FirstQuestion,
    SecondQuestion,
    SecondBQuestion,
    SecondCQuestion,
    ThirdQuestion,
    FourthQuestion,
    FifthQuestion,
    AnalyzingSection,
    FormSection,
  },

  data() {
    return {
      question: "1",
      value: 1,
      max: 4,
      details: {
        language: "nl_BE",
        site_custom_name: "belgischadvies_waterontharder",
        site_custom_url: "https://belgischadvies.be/waterontharder",
        publisher_id: "",
        site_subid: "",
        house_number: "",
        street: "",
        zip: "",
        gender: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        text_answers: "",
      },
      answers: ["4747"],
      text_answers: "",
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.details.publisher_id = this.$route.query.site;
    this.details.site_subid = this.$route.query.click;
  },
  methods: {
    nextQuestion(que, ans, answerId) {
      console.log("que: ", que);
      if (que == "2B" || que == "2C") {
        this.question = que;
      } else {
        this.question = que;
        this.value = que;
        if (answerId) {
          this.answers.push(answerId);
        }
      }

      if (ans) {
        let temp = [...this.answers];
        temp.push(ans);
        this.answers = temp;
      }
    },
    goToPrevious() {
      this.$emit("previousPage");
    },
    previousQuestion(que) {
      if (que == "2") {
        this.answers = ["4747"];
      }
      this.question = que;
      this.value = que;
    },
    setSelectedStreet(street) {
      this.details.street = street;
    },
  },
  mounted() {
    if (this.postdata?.typePost) {
      this.question = "1";
      this.details.house_number = this.postdata.house_number;
      this.details.zip = this.postdata.zip;
    }
  },

  watch: {
    "details.house_number"(newVal, oldVal) {
      // this.getStreets({
      //   zip: this.details.zip,
      //   house_number: this.details.house_number,
      // });
      this.getStreetsByZip({ zip: this.details.zip });
    },
    "details.text_answers"(newVal, oldVal) {
      console.log("Data", newVal);
    },
    answers: {
      handler(newAnswers, oldAnswers) {
        console.log("Answers changed:", newAnswers);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff5cc;

  .progress {
    height: 20px;
    border-radius: 14px;
    background-color: #ffffff;
  }

  .progress-bar {
    height: 20px;
    border-radius: 14px;
    background-color: #5cba01;
  }
}
</style>
