import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import global from "./mixins/global";

import * as VueGoogleMaps from "vue2-google-maps";
import "vue-multiselect/dist/vue-multiselect.min.css";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCIUep3Zbrrok-Wm4Nsj_nwgWKZXIVUjBo",
    libraries: "places", // If you need to use other libraries like 'geometry', 'visualization', etc.
  },
});

Vue.config.productionTip = false;
Vue.mixin(global);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
