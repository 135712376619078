import { render, staticRenderFns } from "./FourthA3_Question.vue?vue&type=template&id=2944c502&scoped=true&"
import script from "./FourthA3_Question.vue?vue&type=script&lang=js&"
export * from "./FourthA3_Question.vue?vue&type=script&lang=js&"
import style0 from "./FourthA3_Question.vue?vue&type=style&index=0&id=2944c502&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2944c502",
  null
  
)

export default component.exports