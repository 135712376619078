<template>
  <div
    class="work-card d-flex flex-column justify-content-center align-items-center"
  >
    <div class="work-card__icon">
      <svg
        v-if="item.step === 'Stap 3'"
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.4813 21.6048H19.2298V26.3555H33.4813V21.6048Z"
          fill="black"
        />
        <path
          d="M33.4813 31.1055H19.2298V35.8563H33.4813V31.1055Z"
          fill="black"
        />
        <path
          d="M38.2313 40.6063H19.2298V45.3578H38.2313V40.6063Z"
          fill="black"
        />
        <path
          d="M42.982 50.1078H19.2298V54.8578H42.982V50.1078Z"
          fill="black"
        />
        <path
          d="M52.4828 17.0928V12.1033C52.4828 9.49175 50.3444 7.3526 47.7328 7.3526H14.479C11.8675 7.3526 9.7283 9.49104 9.7283 12.1033V59.6086C9.7283 62.2201 11.8675 64.3593 14.479 64.3593H47.7328C50.3444 64.3593 52.4828 62.2201 52.4828 59.6093V40.3683C57.9034 39.266 61.9843 34.476 61.9843 28.7306C61.9843 22.9851 57.9034 18.1951 52.4828 17.0928ZM47.7328 59.6093H14.479V12.104H47.7328V17.0935C42.3122 18.1951 38.2313 22.9851 38.2313 28.7306C38.2313 34.476 42.3122 39.266 47.7328 40.3683V59.6093ZM49.2677 33.9801L42.9699 27.6802L45.4889 25.1613L49.2677 28.9401L54.726 23.4817L57.2449 26.0007L49.2677 33.9801Z"
          fill="black"
        />
      </svg>
      <svg
        v-if="item.step === 'Stap 2'"
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.1078 50.1085H31.1055V54.8593H50.1078V50.1085Z"
          fill="#1C1829"
        />
        <path
          d="M50.1078 40.6063H31.1055V45.3571H50.1078V40.6063Z"
          fill="#1C1829"
        />
        <path
          d="M35.8563 31.1055H31.1055V35.8556H35.8563V31.1055Z"
          fill="#1C1829"
        />
        <path
          d="M50.1078 21.6041V12.1033C50.1078 9.4775 47.9786 7.3526 45.357 7.3526H16.854C14.2303 7.3526 12.104 9.48177 12.104 12.1033V50.1085C12.104 52.7344 14.2303 54.8593 16.854 54.8593H21.6048V59.6093C21.6048 62.2308 23.7318 64.36 26.3555 64.36H54.8585C57.4793 64.36 59.6085 62.2358 59.6085 59.6093V31.1056L50.1078 21.6041ZM52.8911 31.1056H45.357V23.5737L52.8911 31.1056ZM21.6048 21.6041V50.1085H16.854V12.1033H45.357V16.8541H26.3555C23.7318 16.8541 21.6048 18.9783 21.6048 21.6041ZM54.8585 59.6093H26.3555V21.6041H40.607V31.1056C40.607 33.7264 42.7333 35.8556 45.357 35.8556H54.8585V59.6093Z"
          fill="#1C1829"
        />
      </svg>
      <svg
        v-if="item.step === 'Stap 1'"
        width="73"
        height="72"
        viewBox="0 0 73 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64.367 37.1248L57.6496 30.4031C56.7261 29.4796 55.214 29.4796 54.2912 30.4074L32.914 51.7789L30.4135 64.3559L42.9898 61.8547L64.3663 40.4832C65.2905 39.5604 65.2905 38.0483 64.367 37.1248ZM40.6525 57.4774L36.4583 58.3125L37.2934 54.1176L55.9708 35.446L59.332 38.8043L40.6525 57.4774Z"
          fill="#1C1829"
        />
        <path
          d="M24.6804 59.6107H12.8047V12.1019H29.4312V23.9798C29.4312 26.6014 31.5575 28.7305 34.1812 28.7305L50.8077 28.7284V23.9798L34.1812 7.35187H12.8047C10.181 7.35187 8.05393 9.4789 8.05393 12.1026V59.6114C8.05393 62.2322 10.181 64.3614 12.8047 64.3614H24.6804V59.6107ZM34.1812 14.0686L44.0903 23.9798H34.1812V14.0686Z"
          fill="#1C1829"
        />
      </svg>
    </div>
    <p class="work-card__title mb-0 mt-3">{{ item?.step }}</p>
    <p class="work-card__description mt-2">{{ item?.description }}</p>
  </div>
</template>

<script>
export default {
  name: "WorkCard",
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.work-card {
  padding: 30px 20px;
  background: #ffffff;
  margin-top: 80px;
  min-height: 199px;

  &__icon {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 10px;
    background: #ffcc01;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    img {
      width: 70% !important;
    }
  }

  &__title {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #1d1729;
    font-weight: 800;

    text-align: center;
  }
  &__description {
    font-size: 15px;
    // line-height: 24px;
    line-height: 20px;
    color: #6c6a72;
    font-weight: 400;

    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1199.99px) {
    min-height: 219px !important;
    &__icon {
      margin-top: -95px;
    }
  }
}
</style>
