<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Omschrijf het project (optioneel)
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <!-- v-slot="{ ariaDescribedby }" -->
            <b-form-group>
              <div class="row no-gutters">
                <div class="col-md-10 d-flex align-items-baseline">
                  <b-form-textarea
                    class="w-100"
                    v-model="details.text_answers"
                    type="text"
                  ></b-form-textarea>
                  <!-- <b-button
                    class="mt-3 mx-2 px-3 button"
                    @click="removeField"
                    v-if="fieldCount > 1"
                  >
                    -
                  </b-button> -->
                </div>
              </div>

              <!-- <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick('3884')">
                  <b-form-radio value="3884">Schuin dak </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('3881')">
                  <b-form-radio value="3881">Plat dak</b-form-radio>
                </div>
              </b-form-radio-group> -->
            </b-form-group>
            <!-- <b-button
              v-if="fieldCount < 5"
              class="mt-3 px-3 button"
              @click="addField"
            >
              +
            </b-button> -->

            <b-button class="mt-3 w-100" @click="handleClick" type="submit">
              GA VERDER
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
        <!-- <div
          class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
        >
          <span>Walter gropiuserf 6, Amersfoort</span>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "FourthQuestion",
  data() {
    return {
      value: "",
      selected: "",
      // fieldCount: 1,
      // answerIds: ["4713", "4716", "4719", "4722", "4725"],
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    text_answers: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // addField() {
    //   if (this.fieldCount < 5) {
    //     this.fieldCount++;
    //   }
    // },
    // removeField() {
    //   if (this.fieldCount > 1) {
    //     this.fieldCount--;
    //   }
    // },
    handleClick(val) {
      // this.$emit(
      //   "nextQuestion",
      //   "analyze",
      //   this.answerIds.slice(0, this.fieldCount)
      // );

      this.$emit("nextQuestion", "analyze", "");
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },
  },
  watch: {
    selected() {
      this.$emit("nextQuestion", "analyze", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 45px;
  height: 45px;
}
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
