import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ThankYou from "../views/ThankYou.vue";
import AirconditioningThankYou from "../views/AirconditioningThankYou.vue";
import ThankYouIsolate from "../views/ThankYouIsolate.vue";
import Airconditioning from "../views/Airconditioning.vue";
import IsolateHome from "../views/IsolateHome.vue";
import Waterontharder from "../views/Waterontharder.vue";
import WaterontharderThankYou from "../views/WaterontharderThankYou.vue";
import Dakwerken from "../views/Dakwerken.vue";
import DakwerkenThankyou from "../views/DakwerkenThankyou.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/pvcramendeuren",
  },
  {
    path: "/pvcramendeuren",
    name: "Home",
    component: Home,
  },
  {
    path: "/airconditioning",
    name: "Airconditioning",
    component: Airconditioning,
  },
  {
    path: "/kunststofkozijnen",
    name: "Home2",
    component: Home,
  },
  {
    path: "/bedankt",
    name: "Bedankt",
    component: ThankYou,
  },
  {
    path: "/airconditioning-bedankt",
    name: "AirconditioningBedankt",
    component: AirconditioningThankYou,
  },
  {
    path: "/isolatie-bedankt",
    name: "Thanks",
    component: ThankYouIsolate,
  },
  {
    path: "/isolatie",
    name: "Home",
    component: IsolateHome,
  },
  {
    path: "/waterontharder-bedankt",
    name: "Bedankt",
    component: WaterontharderThankYou,
  },
  {
    path: "/waterontharder",
    name: "Home",
    component: Waterontharder,
  },
  {
    path: "/dakwerken",
    name: "Home",
    component: Dakwerken,
  },
  {
    path: "/dakwerken-bedankt",
    name: "Bedankt",
    component: DakwerkenThankyou,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
