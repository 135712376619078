<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />

        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Geschatte oppervlakte van het dak
        </p>
        <b-form @submit="handleClick">
          <div class="row no-gutters">
            <b-col class="mt-3 d-flex align-items-center" cols="12" md="6">
              <b-form-input
                type="number"
                placeholder="Aantal"
                required
                v-model="details.text_answers"
              ></b-form-input>
              <span class="ml-1">m2</span>
            </b-col>

            <b-button class="w-100 p-2 mt-3" type="submit"> volgende </b-button>
          </div>
        </b-form>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection :details="details" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import { BModal, BButton } from "bootstrap-vue";

export default {
  name: "FourthC1_Question",

  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    text_answers: {
      type: String,
      // required: true,
    },
    question: {
      type: Number,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", "4C2", "");
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },

  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
// .question-card {
//   &__right-col {
//     background-image: url(../../../assets/imgs/secondQuestion.png);
//     background-size: 100% 100% !important;
//   }
// }
</style>
