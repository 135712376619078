<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <svg
          @click="goToPreviousPage"
          class="back-arrow"
          width="32"
          height="25"
          viewBox="0 0 32 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.570872 11.1186L11.0923 0.571695L13.8454 0.571695L13.8454 3.33452L6.65089 10.5469L30.0508 10.5469L32 12.4999L30.0508 14.4531L6.65089 14.4531L13.8454 21.6654L13.8454 24.4284L12.47 25L11.0923 24.4284L0.570872 13.8816L0.570872 11.1186Z"
            fill="#FFCD02"
          />
        </svg>
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Omschrijf het project (optioneel)</p>

        <b-form @submit="handleNextQuestion">
          <div class="row no-gutters">
            <div class="col-md-10">
              <b-form-textarea
                placeholder="(optioneel)"
                type="text"
                v-model="details.text_answers"
              ></b-form-textarea>
              <!-- <b-form-invalid-feedback v-if="!houseNumberValidation">
                Voer een geldige aantal bewoners in
              </b-form-invalid-feedback> -->
              <b-button class="mt-3 w-100" type="submit"> GA VERDER </b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection :details="details" />
        <!-- <div
          class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
        >
          <span>Walter gropiuserf 6, Amersfoort</span>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../../GoogleMapSection.vue";

export default {
  name: "FirstQuestion",
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    text_answers: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      value: "",
      houseRegex: /^[0-9]+$/,
    };
  },
  components: {
    GoogleMapSection,
  },
  methods: {
    handleNextQuestion(e) {
      e.preventDefault();
      // if (this.isValidForm) {
      //   if (this.value >= 3) {
      //     this.$emit("nextQuestion", "analyze", "1857");
      //   } else if (this.value == 1) {
      //     this.$emit("nextQuestion", "analyze", "1857");
      //   } else if (this.value == 2) {
      //     this.$emit("nextQuestion", "analyze", "1857");
      //   }
      //}
      this.$emit("nextQuestion", "analyze", "");
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },
  },
  computed: {
    // houseNumberValidation() {
    //   return this.houseRegex.test(this.value) || this.value === "";
    // },
    // isValidForm() {
    //   return this.houseNumberValidation;
    // },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    // background-image: url(../../../assets/imgs/secondQuestion.png);
    // background-size: 100% 100% !important;
  }
}
</style>
