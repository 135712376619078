<template>
  <div class="solar">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center flex-column"
        >
          <!-- <p class="solar__title-small mb-0">Waarom isolatie?</p> -->
          <p class="solar__title">Waarom isolatie?</p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Blijft de energiefactuur stijgen terwijl er in het verbruik niks is
            veranderd? Denk dan eens na over het isoleren van je woning.
          </p>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <svg
              width="24"
              class="check-img"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C5.38442 0 0 5.38443 0 12C0 18.6156 5.38442 24 12 24C18.6156 24 24 18.6156 24 12C24 5.38443 18.6156 0 12 0ZM12 2C17.5347 2 22 6.46531 22 12C22 17.5347 17.5347 22 12 22C6.4653 22 2 17.5347 2 12C2 6.46531 6.4653 2 12 2ZM16.9492 8L10.6992 14.25L7.44922 11L6.05078 12.4004L10.6992 17.0508L18.3496 9.40039L16.9492 8Z"
                fill="#FFCD02"
              />
            </svg>
            <span class="solar__description ml-1 ml-md-3">
              Hoger wooncomfort (houdt het warm in
              <br class="d-lg-flex d-xl-none" />
              de winter en koel in de zomer);</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <svg
              width="24"
              class="check-img"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C5.38442 0 0 5.38443 0 12C0 18.6156 5.38442 24 12 24C18.6156 24 24 18.6156 24 12C24 5.38443 18.6156 0 12 0ZM12 2C17.5347 2 22 6.46531 22 12C22 17.5347 17.5347 22 12 22C6.4653 22 2 17.5347 2 12C2 6.46531 6.4653 2 12 2ZM16.9492 8L10.6992 14.25L7.44922 11L6.05078 12.4004L10.6992 17.0508L18.3496 9.40039L16.9492 8Z"
                fill="#FFCD02"
              />
            </svg>
            <span class="solar__description ml-1 ml-md-3">
              Lagere energiefactuur en stookkosten;</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <svg
              width="24"
              class="check-img"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C5.38442 0 0 5.38443 0 12C0 18.6156 5.38442 24 12 24C18.6156 24 24 18.6156 24 12C24 5.38443 18.6156 0 12 0ZM12 2C17.5347 2 22 6.46531 22 12C22 17.5347 17.5347 22 12 22C6.4653 22 2 17.5347 2 12C2 6.46531 6.4653 2 12 2ZM16.9492 8L10.6992 14.25L7.44922 11L6.05078 12.4004L10.6992 17.0508L18.3496 9.40039L16.9492 8Z"
                fill="#FFCD02"
              />
            </svg>
            <span class="solar__description ml-1 ml-md-3">
              Bijdrage aan een beter milieu</span
            >
          </div>
          <p class="solar__description mt-2 mt-md-4 text-justify">
            Bovendien is een airconditioning multifunctioneel: hij verwarmt je
            huis ook gemakkelijk op koude winterdagen. Zo bespaar jij enorm op
            je gasrekening.
          </p>
        </b-col>
        <b-col cols="12" md="1"></b-col>
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex align-items-center justify-content-start position-relative"
        >
          <img
            src="@/assets/imgs/solarImg.jpg"
            class="img-fluid"
            alt="Solar Image"
            width="100%"
            height="auto"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SolarPanel",
};
</script>
<style lang="scss" scoped>
.solar {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  &__title-small {
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }

  &__title {
    font-size: 35px;
    color: #1d1729;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;

    // letter-spacing: 2px;
    // line-height: 33px;
    line-height: 30px;
    color: #1d1729;
    font-weight: 600;
  }

  &__panel {
    &-text {
      position: absolute;
      text-align: center;
      right: 44px;
      top: 88px;

      &-title {
        // font-size: 36px;
        font-size: 25px;

        color: #1d1729;
        font-weight: 900;
      }

      &-sub-title {
        // font-size: 16px;
        font-size: 12px;
        color: #1d1729;
        font-weight: 500;
      }
    }

    // &-text > p {
    //   font-size: calc(1vw + 1vh);
    //   margin: 0;
    // }
  }

  @media (max-width: 1199.99px) {
    &__title-small {
      font-size: 14px;
    }

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media (max-width: 992.99px) {
    &__title-small {
      font-size: 13px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      font-size: 14px;
    }
  }
  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title-small {
      font-size: 12px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__description {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 577.99px) {
    &__title-small {
      font-size: 11px;
    }

    &__title {
      font-size: 20px;
      line-height: 20px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }
}
</style>
